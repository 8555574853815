import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { isEqual } from 'lodash/fp';
import { createContext, memo, useEffect, useMemo, useState, type FC } from 'react';
import { twMerge } from 'Utils/customTwMerge';

import { useExpandableContainer } from './useExpandableContainer';

type CardProps = {
    header: React.ReactNode;
    body: React.ReactNode;
    cardClassName?: string;
    buttonClassName?: string;
};

const classes = {
    card: clsx([
        'tw-leading-none',
        'tw-w-full',
        'tw-rounded',
        'tw-bg-white',
        'tw-shadow-[0px_0px_10px_0px_rgba(0,0,0,.1)]',
        'tw-mb-2',
        'last:tw-mb-0',
    ]),
    titleBox: clsx(['tw-flex', 'tw-min-h-[30px]', 'tw-items-center', 'tw-w-full']),
    container: clsx([
        'tw-flex',
        'tw-justify-between',
        'tw-items-center',
        'tw-min-h-14',
        'tw-ms-[15px]',
        'tw-me-[15px]',
        'sm:tw-me-[30px]',
        'tw-bg-transparent',
        'hover:tw-cursor-pointer',
    ]),
    checkIcon: clsx([
        'tw-ms-[5px]',
        'tw-h-[14px]',
        'tw-w-[14px]',
        'tw-flex-shrink-0',
        'tw-inline-block',
        'tw-align-[-.125em]',
    ]),
    chevronClasses: clsx([
        'tw-w-[30px]',
        'sm:tw-w-auto',
        'hover:tw-text-blue',
        'tw-transition-transform',
        'tw-duration-[250ms]',
    ]),
    bodyWrapper: clsx(['tw-transition-opacity', 'tw-duration-[300ms]']),
};

export const ExpandableCardListContext = createContext<{ open: boolean }>({ open: false });

export const ExpandableCardList: FC<CardProps> = memo(({ header, body, cardClassName, buttonClassName }) => {
    const { open, ExpandableContainer, toggleOpen, handleKeyDown } = useExpandableContainer();
    const contextValue = useMemo(() => ({ open }), [open]);

    const [expanded, setExpanded] = useState(open);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (open && !expanded) {
            setExpanded(true);
        } else if (!open && expanded) {
            timeoutId = setTimeout(() => setExpanded(false), 200);
        }

        return () => clearTimeout(timeoutId);
    }, [open, expanded]);

    return (
        <ExpandableCardListContext.Provider value={contextValue}>
            <div className={twMerge(classes.card, cardClassName)}>
                <ExpandableContainer
                    className={twMerge(classes.container, buttonClassName)}
                    onKeyDown={handleKeyDown}
                    onClick={toggleOpen}
                >
                    <div className={classes.titleBox}>{header}</div>
                    <FontAwesomeIcon
                        className={clsx(classes.chevronClasses, { 'tw-rotate-180': open }, { 'tw-rotate-0': !open })}
                        icon={faChevronDown}
                        color={open ? '#4C79FF' : '#A9A197'}
                    />
                </ExpandableContainer>
                <div
                    className={clsx(
                        classes.bodyWrapper,
                        { 'tw-opacity-0 tw-ease-out': !open },
                        { 'tw-opacity-100 tw-ease-in': open },
                    )}
                >
                    {expanded && body}
                </div>
            </div>
        </ExpandableCardListContext.Provider>
    );
}, isEqual);
