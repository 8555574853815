/* eslint-disable func-names */
import Papa from 'papaparse';
import cacheAngularTemplate from 'cacheAngularTemplate';
import ClientStorage from 'ClientStorage';
import template from '../views/editable_things_list.html';
import angularModule from './editable_things_list_module';

const templateUrl = cacheAngularTemplate(angularModule, template);

// FIXME: There's some inconsistency with how we're handling boolean filter values on the server. If you look at `scope.updateFilters()`
// in `edit_content_item_list_mixin.js` (which should probably get renamed at some point since it's used in places like this that have
// nothing to do with content items) any active client filter whose value is listed in the `booleanProperties` array gets it's value
// converted from a string to it's boolean counterpart (e.g. the string `'true'` gets converted to the boolean `true`). But if you look
// at `users_controller.rb` and `users_controller_spec.rb` we expect the filter values to be strings. Alo, in controller specs, if you
// pass in a boolean filter value, it gets converted into a string in the controller rather than remaining a boolean

/*
 * Inspired by post from Eric Ferreira <http://stackoverflow.com/users/2954747/eric-ferreira> ©2015
 *
 * This filter will sit in the filter sequence, and its sole purpose is to record
 * the current contents to the given property on the target object. It is sort of
 * like the 'tee' command in *nix cli.
 *
 * Modified to also take an enabled property to make it easy to turn it on and off.
 *
 * See https://stackoverflow.com/a/32787564/1747491
 */
angularModule.filter('record', [
    '$injector',
    () => (array, property, target, enabled) => {
        enabled = angular.isDefined(enabled) ? enabled : true;

        if (target && property && enabled) {
            target[property] = array;
        }
        return array;
    },
]);

angularModule.directive('editableThingsList', [
    '$injector',

    function factory($injector) {
        const injector = $injector.get('injector');
        const HasSortableColumnsMixin = injector.get('HasSortableColumnsMixin');
        const $location = injector.get('$location');
        const $parse = injector.get('$parse');
        const $filter = injector.get('$filter');
        const $window = injector.get('$window');
        const contentItemEditorLists = injector.get('contentItemEditorLists');

        // See comment in editable_things_list_module
        const RecentlyEditedLessons = injector.get('RecentlyEditedLessons', { optional: true });
        const PaginationService = injector.get('paginationService');
        const scopeTimeout = injector.get('scopeTimeout');
        const $timeout = injector.get('$timeout');
        const scrollHelper = injector.get('scrollHelper');
        const jQueryHelper = injector.get('jQueryHelper');
        const ngToast = injector.get('ngToast');
        const $rootScope = injector.get('$rootScope');
        const Cohort = injector.get('Cohort');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                // 'filters' is an optional array of descriptors for filters that can be
                // applied either client-side or server-side.
                //
                // For an example, see adminCareers#filtersForCareerProfiles
                //
                // FIXME: filters currently only supports hardcoded filters to be passed
                // to the sever in the api call.  It should also support descriptors
                // of dynamic client-side and server-side filters that are useful for
                // viewing the results. See admin_organization_options for an example
                filters: '<',

                // Determines wether we are filtering and paginating on the client-side or server-side
                usingServerPagination: '<?',

                // Hook to signal when filters are being reset. Two-way bound to allow unsetting in parent scope.
                resettingFilters: '=?',

                // when true, all results are shown on one page
                disablePagination: '<?',

                // the column to sort by initially. 'created_at' by default
                defaultSort: '<?',

                // the order to sort by initially. 'desc' by default
                defaultSortOrder: '<?',

                // the name of a factory that returns a class, i.e. 'CareerProfile'
                klassName: '<',

                // the dasherized name of a directive that can be used
                // to edit a particular instance.  i.e. admin-edit-career-profile
                //
                // The directive should accept a `thing` scope variable and optionally
                // define the `goBack`, `gotoThing`, and `created` callbacks.
                editDirective: '<',

                // An object that will be passed through to the edit-directive when editing a thing
                editDirectivePassthrough: '<',

                // A view-model that allows deeper directives, such as the editDirective, to change information
                // on containing directives (e.g. mainBox's header).
                containerViewModel: '<?',

                // an array of descriptors for the columns in the table
                columns: '<',

                // Properties to use for quick filtering. (Default: All column properties)
                quickFilterProperties: '<?',

                // a boolean indicating whether there should be a button
                // for creating a new instance.  Clicking the button will
                // create a new instance using Klass.new(), and pass that new
                // instance into the editDirective.
                //
                // If the new instance is saved, the directive should
                // use the created() callback to ensure the new
                // instance is added to the list of things
                allowCreate: '<?',

                // Provides support for the default row-click editing action. (Defaults to true)
                allowEdit: '<?',

                // Optional function to call when clicking a list item
                onItemClick: '<?',

                // Allows the list call to limit to specific fields to optimize query performance
                apiFields: '<?',

                // Allows specification of top-level controller params. If a 'filters' object property
                // is present, any of those filters that aren't also included in the 'filters' isolate
                // scope property above will not be included in the API call. This is useful for initializing
                // the list of editable things from an initial set of API filters.
                indexParams: '<?',

                // onCreate callback
                onCreateCallback: '<?',

                // onLoad callback,
                onLoadCallback: '<?',

                // Custom tip text
                customTip: '<?',

                // Persisted sort key and page values
                persistSortKey: '<?',

                // Whether to show the CSV export link for the current visible set of items (Defaults to true)
                showExportCsv: '<?',

                // an array of descriptors for the columns to export (Defaults to the `columns` array)
                // FIXME: refactor this into a property on each column, the way csvOnly is implemented
                csvExportColumns: '<?',

                // a boolean flag to prevent certain actions from occurring if unsaved changes are present (Defaults to false)
                hasUnsavedChanges: '<?',

                // Using two-way binding here because the idea is that the parent directive might
                // want access to the meta that came down from the index call
                listMeta: '=?',

                // These are intended to be used for the parent scope to access the things,
                // not for the things to be passed in from the parent scope
                things: '=?',
                visibleThing: '=?',
                getThingsOnCurrentPage: '=?',

                // the number of things to show on each page
                // and the options available to select from
                perPage: '=?', // two-way bound so that updates in this directive are reflected in the parent
                perPageOptions: '<?',

                // two-way bound for use in the parent scope
                getCurrentPage: '=?',
                goToPage: '=?',

                // set this to false if your class does not support show request
                supportsShowRequests: '<?',
                forceShowRequest: '<?',

                // Not in scope, but available as a settable attribute: editing-is-open
                // If supplied, creates binding to a variable that indicates if we're editing something

                // Set this to true if you want to add an extra UI component to handle hiding/showing
                // columns. Set a column to `hiddenByDefault = true` to hide on initial render. Set a column to
                // `unhideable = true` to disable hiding for that column.
                advancedColumnDisplayMode: '<?',
            },
            link(scope, elem, attrs) {
                const idQueryParamKey = scope.klassName ? `${scope.klassName.toLowerCase()}-id` : 'id';

                // support old links that still have the id query param
                if ($location.search().id) {
                    $location.search(idQueryParamKey, $location.search().id);
                    $location.search('id', null);
                }

                //------------------------
                // Defaults
                //------------------------

                scope.paginationId = `pagination${scope.$id}`;

                HasSortableColumnsMixin.onLink(scope);
                scope.defaultSort = scope.defaultSort || 'created_at';
                scope.defaultSortOrder = scope.defaultSortOrder || 'desc';
                scope.indexParams = scope.indexParams || {};
                scope.editableThingsListViewModel = {
                    ngToast,
                    goBack() {
                        scope.showThing(null);
                        // since this is called from react, we need to force a digest
                        $injector.get('safeApply')(scope);
                    },
                    onSaved(thing, isNew) {
                        if (isNew) {
                            scope.onCreated(thing);
                        }

                        ngToast.create({
                            content: 'Saved',
                            className: 'success',
                        });

                        $injector.get('safeApply')(scope);
                    },
                    // We take an id here instead of the actual thing so that
                    // the caller can use it even if they only have a proxy object
                    onDestroyed(thingId) {
                        const thing = _.find(scope.things, {
                            id: thingId,
                        });
                        if (thing) {
                            scope.onDestroyed(thing);
                        }
                        this.goBack();
                        $injector.get('safeApply')(scope);
                    },
                };

                scope.$watchGroup(['disablePagination', 'usingServerPagination'], () => {
                    if (scope.disablePagination) {
                        scope.perPage = Number.MAX_SAFE_INTEGER;
                    } else if (!scope.perPage) {
                        scope.perPage = scope.usingServerPagination
                            ? scope.indexParams.limit || scope.indexParams.per_page || 100
                            : scope.perPage || 20;
                    }
                });

                scope.$location = $location;

                scope.allowCreate = angular.isDefined(scope.allowCreate) ? scope.allowCreate : true;
                scope.allowEdit = angular.isDefined(scope.allowEdit) ? scope.allowEdit : true;
                scope.showExportCsv = angular.isDefined(scope.showExportCsv) ? scope.showExportCsv : true;
                scope.csvExportColumns = angular.isDefined(scope.csvExportColumns)
                    ? scope.csvExportColumns
                    : scope.columns;
                scope.hasUnsavedChanges = angular.isDefined(scope.hasUnsavedChanges) ? scope.hasUnsavedChanges : false;
                scope.embedded = angular.isDefined(scope.embedded) ? scope.embedded : false;
                scope.supportsShowRequests = angular.isDefined(scope.supportsShowRequests)
                    ? scope.supportsShowRequests
                    : true;
                scope.forceShowRequest ||= () => false;

                // We need to cache the initial path so we can check later if an id param change
                // is applicable to this editable-things-list or not. We were previously seeing
                // an issue when trying to navigate to another route with an editable-things-list directly
                // using an id param because the editable-things-list currently displayed would trigger
                // the id param change logic and do a show call that would 404.
                const initialPath = $location.path();

                // binding to a variable that indicates if editing is active
                const isEditingSetter = $parse(attrs.editingIsOpen).assign || angular.noop;

                scope.advancedColumnDisplayMode = angular.isDefined(scope.advancedColumnDisplayMode)
                    ? scope.advancedColumnDisplayMode
                    : false;

                //------------------------
                // Index / Filtering
                //------------------------

                let previousFilters;

                function isContentItem() {
                    return _.includes(['Lesson', 'Playlist', 'Lesson.Stream'], scope.klassName);
                }

                function refreshListItems() {
                    const filterJsonIdentical = JSON.stringify(previousFilters) === JSON.stringify(scope.filters);
                    const previousFiltersUnchanged = !!previousFilters && filterJsonIdentical;
                    const previousFiltersChanged = !!previousFilters && !filterJsonIdentical;

                    // bail fast if filters haven't changed at all and we aren't doing server pagination
                    if (previousFiltersUnchanged && !scope.usingServerPagination) {
                        return;
                    }

                    const klass = scope.klass;
                    if (klass) {
                        const params = {
                            filters: {},
                        };

                        // If using server pagination add the currentPage to the params for the server
                        if (scope.usingServerPagination) {
                            // Reset the page param if the filters have changed, because we can't trust that the new result will have this page
                            if (previousFiltersChanged) {
                                _updatePersistedPage(1);
                            }

                            params.page = scope.currentPage;
                        }

                        // If using server pagination add the values to the params for the server
                        if (scope.usingServerPagination) {
                            if (scope.sort && scope.sort.column) {
                                if (!klass.mapClientSortToServerSort) {
                                    throw new Error(
                                        'Must define mapClientSortToServerSort on klass if usingServerPagination',
                                    );
                                }
                                params.sort = klass.mapClientSortToServerSort(scope.sort.column);
                            }
                            if (scope.sort && scope.sort.descending !== undefined) {
                                params.direction = scope.sort.descending ? 'desc' : 'asc';
                            }
                        }

                        // merge any top-level params provided
                        if (scope.indexParams) {
                            // If a filter specified in the indexParams is not present in the current list of filters (this should
                            // only happen if the filter is user facing and the user unset its value), remove it from the indexParams
                            // filters so it's not included in the API call.
                            // (UPDATE: I couldn't really make sense of this when I came back to add specs for
                            // editable-things-list.  It seems like the following code is broken.  If you have
                            // multiple indexParams and multiple filters, it's always going to delete everything
                            // from indexParams.  I couldn't figure out what it was for though, so I decided
                            // not to touch it and not to test it.)
                            const filterValues = _.map(scope.filters, 'value');
                            // eslint-disable-next-line no-restricted-syntax, guard-for-in
                            for (const prop in scope.indexParams.filters) {
                                for (let i = 0; i < filterValues.length; i++) {
                                    if (!angular.isDefined(filterValues[i][prop])) {
                                        delete scope.indexParams.filters[prop];
                                        // eslint-disable-next-line no-continue
                                        continue;
                                    }
                                }
                            }
                            _.extend(params, scope.indexParams);
                        }

                        // account for things that require server interaction, normalize appropriately
                        let allDefault = true;
                        const serverFilters = _.chain(scope.filters)
                            .filter({
                                server: true,
                            })
                            .forEach(filter => {
                                if (
                                    !filter.default &&
                                    !(isContentItem() && filter.value && angular.isDefined(filter.value.locale))
                                ) {
                                    allDefault = false;
                                }
                                _.extend(params.filters, filter.value);
                            })
                            .value();

                        // construct previous search filters here
                        let previousServerFilters;
                        if (previousFilters) {
                            previousServerFilters = _.chain(previousFilters)
                                .filter({
                                    server: true,
                                })
                                .value();
                        }

                        // special case
                        scope.useRecent = !!_.find(scope.filters, filter => filter.recentlyViewed === true);

                        // locale filtering
                        const localeFilter = _.find(
                            scope.filters,
                            filter => filter.value && angular.isDefined(filter.value.locale),
                        );
                        const locale = localeFilter ? localeFilter.value.locale : 'en';

                        // refresh from
                        if (scope.useRecent && scope.klassName === 'Lesson') {
                            scope.things = new RecentlyEditedLessons().lessons;
                        } else if (
                            !previousServerFilters ||
                            JSON.stringify(serverFilters) !== JSON.stringify(previousServerFilters) ||
                            scope.usingServerPagination
                        ) {
                            // Always refresh if using server pagination

                            // temporary state
                            const _appliedFilters = angular.copy(scope.filters);
                            scope.things = null;

                            // Don't utilize cache if doing server pagination
                            if (allDefault && isContentItem() && !scope.usingServerPagination) {
                                // utilize cached lookup
                                contentItemEditorLists.load(scope.klassName, locale).onLoad(things => {
                                    scope.things = things;

                                    // update previous filters only if call succeeds
                                    previousFilters = _appliedFilters;
                                });
                            } else if (!scope.loadingVisibleThing && !scope.visibleThing) {
                                // standard Index filtering
                                if (scope.apiFields) {
                                    params['fields[]'] = scope.apiFields;
                                }

                                scope.loading = true;
                                klass.index(params).then(response => {
                                    scope.loading = false;
                                    scope.things = response.result;

                                    scope.listMeta = response.meta;

                                    if (scope.onLoadCallback) {
                                        scope.onLoadCallback(scope.things);
                                    }

                                    // Set totalItems appropriately when using server pagination
                                    // See https://github.com/michaelbromley/angularUtils/tree/master/src/directives/pagination#working-with-asynchronous-data
                                    scope.totalItems = scope.usingServerPagination
                                        ? response.meta.total_count
                                        : undefined;

                                    // update previous filters only if call succeeds
                                    previousFilters = _appliedFilters;
                                });
                            }
                        }
                    }
                }

                const applyClientFilters = () => {
                    const quickFilterProperties = scope.quickFilterProperties || _.map(scope.columns, 'prop');
                    const searchValue = scope.searchValue ? scope.searchValue.toLowerCase() : null;
                    const clientFilters = _.filter(scope.filters, { server: false });

                    // filter-function
                    return thing => {
                        if (!thing) return false;

                        let prop;
                        let i;

                        // Advanced UI client filtering
                        if (clientFilters) {
                            // helpers to check various types of filter values
                            const checkBoolean = (thingVal, val) => thingVal === val;

                            const checkString = (thingVal, val) =>
                                thingVal.toString().toLowerCase().includes(val.toString().toLowerCase());

                            const checkArray = (thingVal, val) =>
                                _.intersection([val].flat(), [thingVal].flat()).length > 0;

                            for (i = 0; i < clientFilters.length; i++) {
                                const filter = clientFilters[i];

                                if (filter.value) {
                                    prop = Object.keys(filter.value)[0];
                                    if (prop) {
                                        // handled internally
                                        if (prop === 'locale' && !isContentItem()) {
                                            // eslint-disable-next-line no-continue
                                            continue;
                                        }

                                        // The property assigned in the filters object can be either
                                        // 1. a property available on each thing
                                        // 2. the id of one of the columns
                                        const column = _.find(
                                            scope.columns,
                                            // eslint-disable-next-line no-loop-func
                                            col => col.id === prop || col.prop === prop,
                                        );
                                        const targetVal = filter.value[prop];
                                        const thingVal = column ? scope.getColumnValue(column, thing) : thing[prop];
                                        if (
                                            angular.isDefined(targetVal) &&
                                            angular.isDefined(thingVal) &&
                                            thingVal !== null && // string
                                            ((typeof targetVal === 'boolean' && checkBoolean(thingVal, targetVal)) || // boolean
                                                (typeof targetVal === 'string' && checkString(thingVal, targetVal)) ||
                                                (Array.isArray(targetVal) && checkArray(thingVal, targetVal))) // array of strings
                                        ) {
                                            // allowed to proceed
                                        } else {
                                            return false;
                                        }
                                    } else {
                                        throw new Error('Invalid property for specified value in client filter');
                                    }
                                } else {
                                    throw new Error('Invalid value for client filter');
                                }
                            }
                        }

                        // Quicksearch refinement
                        if (!searchValue) {
                            return true;
                        }
                        for (i = 0; i < quickFilterProperties.length; i++) {
                            prop = quickFilterProperties[i];
                            if (thing[prop] && thing[prop].toString().toLowerCase().includes(searchValue)) {
                                return true;
                            }
                        }
                        return false;
                    };
                };

                Object.defineProperty(scope, 'numThingsAfterFilters', {
                    get() {
                        // use pagination service to fetch the number of displayed items
                        // if we're still initializing and the service isn't registered yet, fallback to the raw list
                        // Note: https://github.com/michaelbromley/angularUtils/tree/master/src/directives/pagination#what-is-the-paginationservice-and-why-is-it-not-documented
                        if (PaginationService.isRegistered(scope.paginationId)) {
                            return PaginationService.getCollectionLength(scope.paginationId);
                        }
                        return scope.things && scope.things.length;
                    },
                });

                //------------------------
                // CRUD
                //------------------------
                scope.showThing = thing => {
                    if (thing === 'create' && scope.allowCreate) {
                        $location.search(idQueryParamKey, 'create');
                    } else if (thing === 'create') {
                        // no-op
                    } else if (thing && scope.allowEdit) {
                        $location.search(idQueryParamKey, thing.id);
                    } else {
                        $location.search(idQueryParamKey, null);
                    }
                };

                scope.assignIsEditing = isOpen => {
                    isEditingSetter(scope.$parent, isOpen);
                };

                scope.handleItemClick = thing => {
                    const isSelectingText = !!$window.getSelection().toString();

                    if (isSelectingText) {
                        // Prevent activating the click handler if the user is trying to select text in the list
                        return;
                    }

                    if (typeof scope.onItemClick === 'function') {
                        scope.onItemClick(thing);
                        return;
                    }

                    scope.showThing(thing);
                };

                //------------------------
                // CRUD Callbacks
                //------------------------

                scope.onCreated = thing => {
                    if (scope.things) {
                        scope.things.push(thing);
                    }
                    if (scope.onCreateCallback) {
                        scope.onCreateCallback(thing);
                    } else {
                        $location.search(idQueryParamKey, thing.id);
                    }
                };

                scope.onDestroyed = thing => {
                    scope.things = _.without(scope.things, thing);
                };

                //------------------------
                // CSV Export
                //------------------------

                scope.$watch('columns', () => {
                    if (!scope.columns) {
                        return;
                    }

                    scope.browserColumns = _.reject(scope.columns, col => col.csvOnly);

                    // ensure we properly freeze columns, etc. if they changed
                    bufferedOnTableRender();
                });

                scope.exportData = {};

                scope.exportCSV = () => {
                    if (
                        scope.hasUnsavedChanges &&
                        !$window.confirm('You have unsaved changes. Are you sure you would like to proceed?')
                    ) {
                        return;
                    }

                    // structure to hold rows
                    const data = [];

                    // add columns as first row can be HTML, so we need to convert it to a string
                    data.push(_.map(scope.csvExportColumns, el => $($.parseHTML(el.label)).text()));

                    const filteredRecords = scope.exportData.filteredRecords;

                    // add rows
                    _.forEach(filteredRecords, row => {
                        data.push(
                            scope.csvExportColumns.map(column => scope.getFormattedColumnValue(column, row, 'csv')),
                        );
                    });

                    // actually export the data
                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';

                    const blob = new Blob([Papa.unparse(data)], {
                        type: 'data:text/csv;charset=utf-8',
                    });

                    const url = $window.URL.createObjectURL(blob);
                    a.href = url;
                    const reportName = scope.klassName + (scope.persistSortKey || '');
                    a.download = `${reportName}-${$filter('amDateFormat')(new Date(), 'YYYY.MM.DD.HH.mm')}.csv`;
                    a.click();
                    $window.URL.revokeObjectURL(url);
                };

                //------------------------
                // Column Display
                //------------------------

                scope.columnDisplayEditMode = false;
                scope.toggleColumnDisplayEditMode = () => {
                    scope.columnDisplayEditMode = !scope.columnDisplayEditMode;
                };

                scope.columnIsVisible = column => {
                    if (!scope.advancedColumnDisplayMode || column.unhideable) {
                        return true;
                    }

                    if (!angular.isDefined(column.hidden) && column.hiddenByDefault) {
                        return false;
                    }

                    if (column.hidden) {
                        return false;
                    }

                    return true;
                };

                scope.allColumnsVisible = () => scope.columns.every(column => scope.columnIsVisible(column));

                scope.onColumnClick = column => {
                    if (scope.columnDisplayEditMode) {
                        scope.toggleColumnVisibility(column);
                    } else if (column.sortable !== false) {
                        scope.changeSorting(column.id);
                    }
                };

                scope.toggleColumnVisibility = column => {
                    if (column.unhideable) {
                        column.hidden = false;
                    } else if (angular.isUndefined(column.hidden)) {
                        column.hidden = !column.hiddenByDefault;
                    } else {
                        column.hidden = !column.hidden;
                    }
                };

                scope.shouldShowSmarterIcon = (prop, klassName, thing, isProgramTypeProp) => {
                    if (prop === 'title' && klassName === 'Playlist') {
                        const programs = Cohort.programTypes.filter(config => config[isProgramTypeProp]);
                        return !!programs.find(config => thing.includedInProgram(config.key));
                    }
                    return false;
                };

                //------------------------
                // Styling
                //------------------------

                scope.columnHeaderClasses = column => {
                    const classes = [column.prop];

                    if ((scope.columnDisplayEditMode && !column.unhideable) || column.sortable !== false) {
                        classes.push('is-clickable');
                    }

                    if (column.classes) {
                        classes.push(column.classes);
                    }

                    return classes;
                };

                scope.headerSpanClasses = column => {
                    let classes = [];

                    // We only want to show one icon at a time - the minus icon when
                    // the table is in column display edit mode, and the arrow-up/arrow-down
                    // icons when not in edit mode, but a column is sortable.
                    if (scope.columnDisplayEditMode && !column.unhideable) {
                        classes.push('is-hideable');
                    } else if (column.sortable !== false) {
                        classes = classes.concat(scope.sortClasses(column.id));
                    }

                    return classes;
                };

                scope.columnClasses = (column, thing) => {
                    let classes = [];
                    if (['checkIfTrue', 'acceptedRejected'].includes(column.type)) {
                        classes.push('text-center');
                    }
                    if (column.classes) {
                        classes = classes.concat(column.classes);
                    }
                    if (column.classesCallback) {
                        classes = classes.concat(column.classesCallback(column, thing));
                    }
                    return classes;
                };

                scope.getColumnValue = (column, thing) => {
                    if (!column || !thing) {
                        return '';
                    }

                    let value;

                    // If a function is used as the value for `prop` in
                    // the column definition, then it should be called with
                    // the `thing` as an argument in order to determine the value
                    if (typeof column.prop === 'function') {
                        value = column.prop(thing);
                    }

                    // Otherwise, prop is a string.  We support dot-syntax for digging
                    // down into the nested properties on the thing
                    else {
                        value = _.reduce(
                            column.prop.split('.'),
                            (previous, subProp) => {
                                if (previous) {
                                    return previous[subProp];
                                }
                                return '';
                            },
                            thing,
                        );
                    }

                    return value;
                };

                scope.getFormattedColumnValue = (column, thing, displayType) => {
                    if (!column || !thing || !column.type) {
                        return '';
                    }

                    if (!_.includes(['csv', 'html'], displayType)) {
                        throw new Error(`Unexpected displayType: ${displayType}`);
                    }

                    const value = scope.getColumnValue(column, thing);

                    // custom formatting functions
                    const formattingHash = {
                        time() {
                            if (value) {
                                return $filter('amDateFormat')(value * 1000, 'MM/DD/YY HH:mm');
                            }
                            return '';
                        },
                        perc100() {
                            if (value && value !== null) {
                                return `${value}%`;
                            }
                            return '';
                        },
                        perc1() {
                            if (value && value !== null) {
                                if (displayType === 'html') {
                                    return `${Math.round(100 * value)}%`;
                                }
                                return `${(100 * value).toFixed(4)}%`;
                            }
                            return '';
                        },
                        commaSeparatedList() {
                            return value ? value.join(', ') : '';
                        },
                        custom() {
                            // In the `html` case, the template does not use this code,
                            // it renders an ng-include with `src=column.template`
                            if (displayType !== 'csv') {
                                throw new Error('Expecting csv display type');
                            }
                            if (column.callbacks && column.callbacks.getFormattedColumnValueForCSV) {
                                return column.callbacks.getFormattedColumnValueForCSV(thing);
                            }
                            return value;
                        },
                    };

                    // If the type on the column is not one of the ones we have
                    // set in the formattingHash (i.e. `getter` below is undefined),
                    // then we fall back to the raw value of the column.
                    //
                    // When rendering in the browser, this means that the column
                    // could be of the type 'text' or 'number'.
                    //
                    // When rendering csv, it could be text, number or one of the types
                    // that is handled specially in the html, like checkIfTrue.
                    const getter = formattingHash[column.type];
                    return getter ? getter() : value;
                };

                //------------------------
                // Sort and Pagination
                //------------------------

                function _updatePersistedPage(newPageNumber) {
                    if (newPageNumber && scope.persistSortKey) {
                        ClientStorage.setItem(`${scope.persistSortKey}_page`, newPageNumber);
                    }
                    scope.currentPage = newPageNumber;

                    // Setting currentPage in the editable-things-list scope doesn't seem to trigger
                    // the expected UI updates in the pagination controls, so we have to manually set
                    // the currentPage for the pagination controls using the PaginationService API.
                    if (PaginationService.isRegistered(scope.paginationId)) {
                        PaginationService.setCurrentPage(scope.paginationId, scope.currentPage);
                    }
                }

                // Callback function passed to dir-pagination-controls that updates the local storage value for page #
                scope.updatePersistedPage = newPageNumber => {
                    _updatePersistedPage(newPageNumber);

                    // With dirPaginate we need to update the currentPage value and refreshListItems when
                    // we are doing server pagination.
                    if (scope.usingServerPagination) {
                        refreshListItems();
                    }
                };

                // lookup previous sort / page values
                scope.currentPage = 1; // dirPagination is 1-indexed
                if (scope.persistSortKey) {
                    const persistedPage = ClientStorage.getItem(`${scope.persistSortKey}_page`);
                    const persistedSort = ClientStorage.getItem(`${scope.persistSortKey}_sort`);
                    if (persistedPage) {
                        scope.currentPage = JSON.parse(persistedPage);
                    }
                    if (persistedSort) {
                        scope.sort = JSON.parse(persistedSort);
                    }
                }

                // defaults fallback
                // Note: If we can't find the persistedSort in the columns list then let's just default.
                // This will protect us against columns changing after they may have been persisted already
                // in someone's client.
                scope.$watch('columns', () => {
                    if (
                        !scope.sort ||
                        !scope.sort.column ||
                        !_.find(scope.columns, {
                            prop: scope.sort.column,
                        })
                    ) {
                        scope.sort = {
                            column: scope.defaultSort,
                            descending: scope.defaultSortOrder === 'desc',
                        };
                    }

                    _.forEach(scope.columns, column => {
                        if (column.prop && !column.id) {
                            if (typeof column.prop === 'function') {
                                throw new Error(
                                    'If a function definition is provided for the column value, then an id must also be provided.',
                                );
                            }
                            column.id = column.prop;
                        }
                    });
                });

                Object.defineProperty(scope, 'sortColumn', {
                    get() {
                        return _.find(scope.columns, {
                            id: scope.sort.column,
                        });
                    },
                });

                // Sorts the things. If the selected column to sort by has no sort callback,
                // sort normally. If a sort callback is provided, use the sort callback to
                // sort the things.
                const sortThings = thing => {
                    const selectedColumn = scope.sortColumn;

                    if (!selectedColumn) {
                        return 0;
                    }

                    if (selectedColumn.callbacks && selectedColumn.callbacks.sort) {
                        return selectedColumn.callbacks.sort(thing, selectedColumn);
                    }

                    const columnValue = scope.getColumnValue(selectedColumn, thing);

                    // Coerce percentage string values to floats for proper sorting.
                    const value = selectedColumn.type === 'perc100' ? Number.parseFloat(columnValue) : columnValue;

                    if (selectedColumn.sortEmptyAs0) {
                        return value || 0;
                    }
                    return value;
                };

                const emptyToEnd = column => {
                    if (!column || column.sortEmptyAs0) {
                        // Treat nothing as blank.  See sortThings for more on how sortEmptyAs0 works
                        return () => true;
                    }
                    if (column.callbacks && column.callbacks.emptyToEnd) {
                        return column.callbacks.emptyToEnd;
                    }
                    return thing => scope.getColumnValue(column, thing);
                };

                //------------------------
                // Watches
                //------------------------

                scope.$watch('klassName', klassName => {
                    scope.klass = klassName && $injector.get(klassName);
                });

                scope.$watchGroup([`$location.search()['${idQueryParamKey}']`, 'things'], () => {
                    // Short-circuit if we detect that the id param change is not applicable to this editable-things-list.
                    // This fixes an issue we saw when trying to directly navigate to another route with an id param
                    // when an editable-things-list was currently loaded.
                    if ($location.path() !== initialPath) {
                        return;
                    }

                    const id = $location.search()[idQueryParamKey];
                    scope.currentView = id ? 'thing' : 'list';
                    if (id === 'create') {
                        scope.visibleThing = scope.klass.new();
                        scope.assignIsEditing(true);
                    } else if (id && (!scope.visibleThing || scope.visibleThing.id !== id)) {
                        // set the thing as the one that corresponds with the id in the URL
                        scope.visibleThing = _.find(scope.things, {
                            id,
                        });

                        // If the thing we're looking for is not loaded, then go
                        // ahead and load it with `show`. (We used to always do the index call first just in
                        // case it would have the thing, but that was slow on, for example, the list users
                        // page.  So now we do the show first).
                        if (scope.supportsShowRequests) {
                            if (!scope.visibleThing || scope.forceShowRequest(scope.visibleThing)) {
                                scope.showSpinner = true;
                                scope.loadingVisibleThing = true;

                                const opts = $rootScope.currentUser.hasAdminAccess
                                    ? { 'fields[]': ['ADMIN_FIELDS'] }
                                    : {};

                                scope.klass.show(id, opts).then(response => {
                                    scope.visibleThing = response.result;
                                    scope.showSpinner = false;
                                    scope.loadingVisibleThing = false;

                                    // Wait a beat so that we can load up whatever we need to
                                    // show this one thing first
                                    $timeout(500).then(setupMainWatcher);
                                });
                            }
                        }

                        scope.assignIsEditing(true);
                    } else if (!id) {
                        scope.visibleThing = null;
                        scope.assignIsEditing(false);
                    }

                    // If we're not loading up an individual thing, then go ahead and
                    // set up the main watcher, which will kick off the index call.
                    // Wait a beat first so that all the filters and things can stabilize
                    if (!scope.loadingVisibleThing && !scope.visibleThing) {
                        $timeout(0).then(setupMainWatcher);
                    }
                });

                let mainWatcherIsSetup = false;

                function setupMainWatcher() {
                    if (mainWatcherIsSetup) {
                        return;
                    }
                    mainWatcherIsSetup = true;
                    scope.$watchGroup(
                        ['indexParams', 'klass', 'filters', 'apiFields', 'visibleThing'],
                        (newVals, oldVals) => {
                            const filtersChanging = newVals[2] !== oldVals[2];

                            // Don't auto-refresh when using server pagination since it involves a network request
                            // and a no-filter database query (which is slow for users admin)
                            if (filtersChanging && scope.resettingFilters) {
                                scope.resettingFilters = undefined;

                                if (scope.usingServerPagination) {
                                    scope.things = undefined;
                                    return;
                                }
                            }

                            if (
                                newVals[0] !== oldVals[0] ||
                                newVals[1] !== oldVals[1] ||
                                filtersChanging ||
                                newVals[3] !== oldVals[3]
                            ) {
                                refreshListItems();
                                return;
                            }

                            const newVisibleThing = newVals[4];
                            if (!newVisibleThing && !_.some(scope.things) && !scope.usingServerPagination) {
                                refreshListItems();
                            }
                        },
                    );
                }

                scope.$watch(
                    'sort',
                    (newVal, oldVal) => {
                        // If the sort value is changing after the initialization then do a refresh
                        if (oldVal && !_.isEqual(newVal, oldVal)) {
                            if (scope.sort && scope.persistSortKey) {
                                ClientStorage.setItem(`${scope.persistSortKey}_sort`, JSON.stringify(scope.sort));
                            }

                            if (scope.usingServerPagination) {
                                refreshListItems();
                            }
                        }
                    },
                    true,
                );

                scope.$on(`admin:${scope.klassName}Destroyed`, (_event, thing) => {
                    scope.onDestroyed(thing);
                });

                //------------------------
                // Frozen columns and sticky scrollbar
                // (this stuff is not tested.  Didn't seem worth it.  Maybe a screenshot spec?)
                //
                // Frozen columns: For tables that are too wide to fit on the screen,
                // it is possible to freeze the first n and/or the last n columns
                // in the table so that they are always visible as you scroll left and
                // right, like in excel.
                //
                // Sticky scrollbar: For tables that are too tall to fit on the screen
                // and wide enough to require horizontal scrolling,
                // we always display a scrollbar at the bottom of the window, so you can
                // scroll left and right without going all the way to the bottom of the page.
                //------------------------

                // This is called whenever a new page of results is rendered, or when
                // the window resizes, altering the automatic layout of the table columns.
                function onTableRender() {
                    positionFrozenColumns();
                    positionStickyScrollbar();

                    // Now that the sticky scrollbar and the table wrappers are on the screen,
                    // watch for either of them to be scrolled and update the other one.
                    jQueryHelper.onUntilDestroyed(
                        scope,
                        elem.find('.sticky-scrollbar'),
                        'scroll',
                        synchronizeScroll.bind(null, elem.find('.sticky-scrollbar')),
                    );
                    jQueryHelper.onUntilDestroyed(
                        scope,
                        tableScrollable(),
                        'scroll',
                        synchronizeScroll.bind(null, tableScrollable()),
                    );
                }

                // This is called when onTableRender is called, or when the user
                // scrolls up and down.  It ensures that the sticky scrollbar
                // stays at the bottom of the window.
                function positionStickyScrollbar() {
                    if (elem.find('table').length === 0) {
                        return;
                    }
                    const stickyScrollBar = elem.find('.sticky-scrollbar');
                    const table = elem.find('table');
                    const distanceFromWindowTopToTableTop = table.offset().top;

                    /*
                        We have to hide the sticky scrollbar when the user is scrolled all
                        the way down so that the regular scrollbar is visible.

                        We could instead just disable scrolling on
                        .frozen-cols-wrapper-2 and always display only the sticky scrollbar,
                        but then touchpad scrolling would not work.  So, we give
                        that element its own scrollbar and then hide the sticky scrollbar
                        if we're scrolled down far enough so that the regular scrollbar
                        is visible.

                        We use visibility instead of hide() because otherwise the scrollbar is
                        a bit off when it first shows up for some reason.
                        */

                    // the extra 15px just comes from guessing and checking and seeing what
                    // works to always give us one and only one scrollbar
                    const tableAndScrollbarHeight = $('.frozen-cols-wrapper-2').outerHeight() - 15;
                    const distanceFromTableBottomToWindowBottom =
                        $($window).height() - distanceFromWindowTopToTableTop - tableAndScrollbarHeight;
                    if (distanceFromTableBottomToWindowBottom > 0) {
                        stickyScrollBar.css({
                            visibility: 'hidden',
                        });
                        return;
                    }
                    stickyScrollBar.css({
                        visibility: 'visible',
                    });

                    // set the width of the scrollbar to the same as the table
                    // (See note about scrollWidth in step 2 of positionFrozenColumns)
                    const tableWidth = table[0].scrollWidth;
                    stickyScrollBar.find(' > div').width(tableWidth);

                    // Figure out how far it is from the top of the table is the bottom
                    // of the window, and put the scroll bar there.
                    const distanceFromTableTopToWindowBottom = $($window).height() - distanceFromWindowTopToTableTop;
                    stickyScrollBar.css({
                        display: tableWidth > tableScrollable().width() ? 'block' : 'none',
                        top: `${Math.min(table.height(), distanceFromTableTopToWindowBottom - 20)}px`,
                    });
                }

                // This is called right after we've rendered a new page of results, or reordered
                // the results on the page.  Basically, if the automatically calculated column
                // widths and row heights might have changed, then we need to start over from scratch
                // and reposition the frozen columns, using the automatically calculated positions
                // as a guide.
                //
                // NOTE: Only set the first n columns on the left and the last n columns
                // on the right to frozen (You set a column to frozen by adding the `frozen` class
                // in the column definition).
                // Nothing will stop you from setting other columns in the middle to
                // frozen, but it will not work.
                function positionFrozenColumns() {
                    const table = elem.find('table');

                    /* 1. Remove previously assigned dynamic styling. */
                    // Remove all dynamic styling we've added in the
                    // past so that the table will be rendered using the regular
                    // html table layout rules
                    elem.find('.frozen').css({
                        position: '',
                        left: '',
                        right: '',
                        width: '',
                    });
                    elem.find('.frozen').removeClass('displaying-as-frozen');

                    elem.find('th,td').each(function () {
                        $(this).css({
                            height: 'auto',
                        });
                    });

                    elem.find('.last-frozen-cell-on-left').removeClass('last-frozen-cell-on-left');
                    elem.find('.first-frozen-cell-on-right').removeClass('first-frozen-cell-on-right');
                    elem.find('th,td').css({
                        paddingLeft: '',
                        paddingRight: '',
                    });

                    /* 2. Abort if we don't need frozen columns. */
                    // No need to freeze columns if the table fits or if there are
                    // no frozen columns, so abort after resetting everything
                    // (NOTE: table.width() here works in Chrome but not Safari.  scrollWidth works in
                    // both and seems to be the right thing conceptually based on the definition of scrollWidth)
                    if (
                        !table[0] ||
                        table[0].scrollWidth <= tableScrollable().width() ||
                        elem.find('tr:eq(0)').find('.frozen').length === 0
                    ) {
                        return;
                    }

                    /* 3. Determine the offsets we will use to absolutely position frozen columns. */
                    // Go through all the columns, recording the widths
                    // of the frozen columns on the left side of the table
                    // and those on the right side.
                    const leftFrozenColumnOffsets = [];
                    const rightFrozenColumnOffsets = [];
                    const leftColumns = [];
                    let rightColumns = [];
                    let activeArray = leftColumns;

                    elem.find('tr')
                        .eq(0)
                        .find('th')
                        .each((_i, el) => {
                            // Once we have found one column that is not frozen,
                            // we stop adding columns to leftColumns and start
                            // adding them to rightColumns
                            if (!$(el).hasClass('frozen')) {
                                activeArray = rightColumns;
                                return;
                            }
                            activeArray.push($(el));
                        });

                    // For the right columns, we want to assign offsets from
                    // the right to the left, so we reverse the list
                    rightColumns = rightColumns.reverse();

                    // On the right and the left, we step through each
                    // column and record the width that was assigned by
                    // the automatic html table layout.
                    let totalLeftOffset = 0;
                    _.forEach(leftColumns, el => {
                        const width = $(el).outerWidth();
                        leftFrozenColumnOffsets.push({
                            offset: totalLeftOffset,
                            width,
                        });
                        totalLeftOffset += width;
                    });
                    let totalRightOffset = 0;
                    _.forEach(rightColumns, el => {
                        const width = $(el).outerWidth();
                        rightFrozenColumnOffsets.push({
                            offset: totalRightOffset,
                            width,
                        });
                        totalRightOffset += width;
                    });

                    /* 4. Go through each cell and set dynamic styles. */
                    // This runs on the th cells in the header and the td cells in the body.
                    elem.find('tr').each(function () {
                        const row = $(this);
                        const cells = row.find('th,td');

                        // Handle the left side of the table, fixing values for `left`
                        // and `width` and assigning special classes as necessary
                        _.forEach(leftFrozenColumnOffsets, (entry, i) => {
                            const cell = $(row).find('.frozen').eq(i);
                            cell.css({
                                left: `${entry.offset}px`,
                                width: entry.width,
                            });
                            cell.addClass('displaying-as-frozen');
                            cell.toggleClass('last-frozen-cell-on-left', i === leftFrozenColumnOffsets.length - 1);
                        });
                        if (leftFrozenColumnOffsets.length > 0) {
                            const firstUnfrozenCell = cells.eq(leftFrozenColumnOffsets.length);
                            firstUnfrozenCell.css({
                                // 15px just comes from guessing and checking
                                paddingLeft: `${totalLeftOffset + 15}px`,
                            });
                        }

                        // Handle the right side of the table
                        _.forEach(rightFrozenColumnOffsets, (entry, i) => {
                            const cell = $(row)
                                .find('th,td')
                                .eq(cells.length - 1 - i);
                            cell.css({
                                right: `${entry.offset}px`,
                                width: entry.width,
                            });
                            cell.addClass('displaying-as-frozen');
                            cell.toggleClass('first-frozen-cell-on-right', i === rightFrozenColumnOffsets.length - 1);
                        });
                        if (rightFrozenColumnOffsets.length > 0) {
                            const lastUnfrozenCell = cells.eq(cells.length - rightFrozenColumnOffsets.length - 1);
                            lastUnfrozenCell.css({
                                // 15px just comes from guessing and checking
                                paddingRight: `${totalRightOffset + 15}px`,
                            });
                        }

                        // Fix all of the heights in this row
                        const maxCellHeight = row.find('th,td').height();
                        row.find('th,td').each(function () {
                            $(this).height(maxCellHeight);
                        });
                    });

                    // set the frozen cells back to absolute positioning
                    elem.find('.displaying-as-frozen').css({
                        position: 'absolute',
                    });
                }

                let onTableRenderTimeout;

                function tableScrollable() {
                    return elem.find('.frozen-cols-wrapper-2');
                }

                function bufferedOnTableRender(delay) {
                    $timeout.cancel(onTableRenderTimeout);
                    onTableRenderTimeout = scopeTimeout(scope, onTableRender, delay);
                }

                function synchronizeScroll(source) {
                    const scrollLeft = source.scrollLeft();
                    elem.find('.sticky-scrollbar').scrollLeft(scrollLeft);
                    tableScrollable().scrollLeft(scrollLeft);
                }

                jQueryHelper.onUntilDestroyed(scope, $($window), 'resize', () => {
                    // We buffer the resize event for performance reasons
                    bufferedOnTableRender(500);
                });

                // bind(null, null) is important so we don't accidentally pass a delay to bufferedOnTableRender
                scope.$watch('perPage', bufferedOnTableRender.bind(null, null));
                scope.$watch('currentPage', bufferedOnTableRender.bind(null, null));
                scope.$watchCollection('exportData.filteredRecords', bufferedOnTableRender.bind(null, null));

                // Whenever the user scrolls the page, we re-position the
                // sticky scrollbar at the bottom of the window
                scrollHelper.watchScrollUntilDestroyed(scope, positionStickyScrollbar);

                Object.defineProperty(scope, 'filteredThings', {
                    get() {
                        // This is the ng-repeat expression that was used with dir-paginate in the template. I moved the logic
                        // for the expression into the directive logic so that we could have direct access to the things visible
                        // on the current page (see thingsOnCurrentPage), which is useful if the parent needs to know exactly
                        // what things are visible on the current page. Note that the itemsPerPage filter is left out of this
                        // logic since it needs to be used in the expression we pass to dir-paginate in the template.
                        // dir-paginate="thing in things | orderBy:sortThings:sort.descending | emptyToEnd:emptyToEnd(sortColumn) | filter:applyClientFilters() | record:'filteredRecords':this.exportData | itemsPerPage:perPage"
                        const sortedThings = $filter('orderBy')(scope.things, sortThings, scope.sort.descending);
                        const sortedThingsEmptyToEnd = $filter('emptyToEnd')(
                            sortedThings,
                            emptyToEnd(scope.sortColumn),
                        );
                        const filteredThings = $filter('filter')(sortedThingsEmptyToEnd, applyClientFilters());
                        const recordedThings = $filter('record')(filteredThings, 'filteredRecords', scope.exportData);
                        return recordedThings;
                    },
                });

                Object.defineProperty(scope, 'thingsOnCurrentPage', {
                    get() {
                        // If we're still initializing and the service isn't registered yet, fallback to the raw list
                        if (PaginationService.isRegistered(scope.paginationId)) {
                            const itemsForCurrentPage = $filter('itemsPerPage')(
                                scope.filteredThings,
                                scope.perPage,
                                scope.paginationId,
                            );
                            return itemsForCurrentPage;
                        }
                        const start = (scope.currentPage - 1) * scope.perPage;
                        const end = start + scope.perPage;
                        return (scope.things || []).slice(start, end);
                    },
                });

                // Gets passed up to the parent for convenient retrieval of the things on the current page.
                scope.getThingsOnCurrentPage = () => scope.thingsOnCurrentPage;

                // Gets passed up to the parent for convenient retrieval of the currentPage.
                scope.getCurrentPage = () => scope.currentPage;

                // Gets passed up to the parent for convenient manipulation of what page is being shown.
                scope.goToPage = page => {
                    const onLastPage = scope.perPage * scope.currentPage >= scope.things.length;
                    if (onLastPage) {
                        return;
                    }
                    scope.updatePersistedPage(page);
                };

                scope.$on('$destroy', () => {
                    $location.search(idQueryParamKey, null);
                });
            },
        };
    },
]);
