function BackButton(props) {
    const { editableThingsListViewModel, label } = props;
    return (
        <button
            type="button"
            className="unstyled-button"
            name="back-to-list"
            onClick={() => {
                editableThingsListViewModel.goBack();
            }}
        >
            <i className="fa fa-arrow-left" />
            {label}
        </button>
    );
}
export default BackButton;
