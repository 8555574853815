import { type DeferralReason, DeferralReasonCategory } from './DeferralReason.types';

export const mockAvailableDeferralReasons = [
    {
        id: '084961e8-2ba7-45e6-852d-136d9aadfd07',
        category: DeferralReasonCategory.staff,
        title: 'Behind learner',
    },
    {
        id: '44c205be-ef73-4a67-8c8b-3c15c9144200',
        category: DeferralReasonCategory.staff,
        title: 'Inability to collect',
    },
    {
        id: 'a5501229-e11f-4953-999a-e85919a66b25',
        category: DeferralReasonCategory.staff,
        title: 'Personal',
    },
    {
        id: 'e67425b0-0ac8-4f17-b745-0cef70ae52f1',
        category: DeferralReasonCategory.staff,
        title: 'ED Deadline - English Language',
    },
    {
        id: 'e1abbc52-3267-423d-ab89-47e4097e18cd',
        category: DeferralReasonCategory.staff,
        title: 'ED Deadline - Enrollment Agreement',
    },
    {
        id: 'e4b7c6a1-c9c2-4a61-ab17-8035ecfb808b',
        category: DeferralReasonCategory.staff,
        title: 'ED Deadline - Unofficial Transcripts',
    },
    {
        id: '3be59156-0189-49f8-b5fb-b3406744f13b',
        category: DeferralReasonCategory.student,
        title: "Can't devote enough time right now",
    },
    {
        id: '98f2e350-2f3c-4b81-bc53-44a81246d26a',
        category: DeferralReasonCategory.student,
        title: 'Life event - job',
    },
    {
        id: '53fb824b-2209-43e2-92db-65a61fd83295',
        category: DeferralReasonCategory.student,
        title: 'Life event - personal/family',
    },
    {
        id: 'aff84a9d-4cc4-4508-90b1-9e17ebc45d42',
        category: DeferralReasonCategory.student,
        title: 'Need time to gather documents',
    },
    {
        id: '555726e9-c129-48a3-b415-c79d8d47564a',
        category: DeferralReasonCategory.student,
        title: 'Need time to get money for tuition',
    },
    {
        id: '544d7685-891d-4d1b-a1ac-8234874e8093',
        category: DeferralReasonCategory.student,
        title: 'Tuition reimbursement timing from employer',
    },
    {
        id: '320b8396-a25d-4d85-b54a-c899beb8e756',
        category: DeferralReasonCategory.student,
        title: 'No reason given',
    },
] as DeferralReason[];

// for server-side purposes only (i.e. not selectable by admins)
export const UNKNOWN_DEFERRAL_REASON = {
    id: '830bff47-f778-4b79-8673-705dbb0c9802',
    category: DeferralReasonCategory.unknown,
    title: 'Unknown',
};
