export enum DeferralReasonCategory {
    staff = 'staff',
    student = 'student',
    unknown = 'unknown', // server-side only
}

export interface DeferralReason {
    id: string;
    title: string;
    category: DeferralReasonCategory;
}

export type DeferralReasonFormValue = {
    deferralCategory: DeferralReasonCategory;
    deferralReasonId: string;
    otherDeferralReasonTitle: string | null;
};
