import 'angular-utils-pagination';
import 'ContentItemEditor/angularModule';
import 'FrontRoyalSpinner/angularModule';
import 'JqueryHelper/angularModule';
import 'Injector/angularModule';
import 'ng-toast';
import 'Pagination/angularModule';
import 'ScrollHelper/angularModule';
import 'SortableColumns/angularModule';
import 'TimeoutHelpers/angularModule';

// This also needs RecentlyEditedLessons, but since that relies
// on FrontRoyal.Lessons, which is huge, I did not want to declare it
// as a dependency.  So I made the factory optional.

export default angular.module('EditableThingsList', [
    'angularUtils.directives.dirPagination',
    'FrontRoyal.ContentItemEditor',
    'FrontRoyalSpinner',
    'Injector',
    'jQueryHelper',
    'scrollHelper',
    'SortableColumns',
    'Pagination',
    'timeoutHelpers',
]);
