import angularModule from 'EditableThingsList/angularModule/scripts/editable_things_list_module';
// TODO: we're using this helper in some of the admin sections (e.g.: Manage Careers)
// We should really rename it, or revisit whether some of this should be merged into the logic of
// editable-things-list itself...
angularModule.factory('editContentItemListMixin', [
    '$injector',

    $injector => {
        const ClientStorage = $injector.get('ClientStorage');
        return {
            // listKey - a string that will be used as a reference key to store the filter preferences in ClientStorage
            // defaultFilters - an array of filter property names that will always get applied
            // booleanProperties - an array of filter property names that correspond to boolean filters
            // customFilterCallback - a function that allows for custom filtering (see list_lessons_dir.js for an example)
            // manualFiltering - a boolean to use when you want to use a manual search button instead of live filtering

            onLink(scope, listKey, defaultFilters, booleanProperties, customFilterCallback, manualFiltering) {
                scope.clientFilters = scope.clientFilters || {};

                // Aggregates form filtering options into a normalized list of filters for editable-things-list to act upon
                scope.updateFilters = () => {
                    const filters = [].concat(defaultFilters);

                    // handle any custom logic
                    if (customFilterCallback) {
                        customFilterCallback(filters);
                    }

                    // tag, title, author, course, modifiedAfterPublished, hasPublishedVersion
                    Object.keys(scope.clientFilters).forEach(prop => {
                        const isBool = _.includes(booleanProperties, prop);
                        const filterValue = scope.clientFilters[prop];
                        const filter = { server: !!scope.usingServerPagination, default: false, value: {} };
                        if (angular.isDefined(filterValue) && (isBool || filterValue)) {
                            filter.value[prop] = isBool ? filterValue === 'true' : filterValue;
                            filters.push(filter);
                        }
                    });

                    // locale filtering
                    const localeFilter = _.find(filters, filter => angular.isDefined(filter.value.locale));
                    if (localeFilter) {
                        localeFilter.default = scope.clientFilters.locale === 'en';
                        localeFilter.value.locale = scope.clientFilters.locale;
                    }

                    // re-assign and kick off digests
                    scope.currentFilters = filters;

                    // update or clear store
                    const allDefault = filters.filter(f => f.default === false).length === 0;
                    if (allDefault) {
                        ClientStorage.removeItem(listKey);
                    } else {
                        ClientStorage.setItem(listKey, JSON.stringify(filters));
                    }
                };

                scope.getPersistedFilters = () => {
                    let filters;
                    try {
                        filters = JSON.parse(ClientStorage.getItem(listKey));
                        filters.forEach(filter => {
                            if (!filter.server || scope.usingServerPagination) {
                                const valKey = Object.keys(filter.value)[0];
                                let val = filter.value[valKey];

                                if (_.includes(booleanProperties, valKey)) {
                                    val = val.toString(); // i hate selectize so much
                                }
                                scope.clientFilters[valKey] = val;
                            } else {
                                if (filter.value.archived) {
                                    scope.archived = true;
                                }
                                if (filter.value.locale) {
                                    scope.clientFilters.locale = filter.value.locale;
                                }
                            }
                        });
                    } catch (e) {} // eslint-disable-line no-empty
                    return filters;
                };

                scope.resetFilters = () => {
                    scope.clientFilters = { locale: 'en' };
                    if (manualFiltering) {
                        scope.updateFilters();
                    }
                    scope.resettingFilters = true;
                };

                // restore any saved filters if available
                scope.currentFilters = scope.getPersistedFilters();

                if (!scope.clientFilters.locale) {
                    scope.clientFilters.locale = 'en';
                }

                // necessary watch + kicks off initial filtering
                if (!manualFiltering) {
                    scope.$watch('clientFilters', scope.updateFilters, true);
                }
            },
        };
    },
]);
