import { useFormContext } from 'FrontRoyalReactHookForm';

/**
    This component provides a save and a destroy button
    for the edit form used with editable-things-list.

    This component implements destroy on its own.  It does
    not implement save, because there seemed to be more variety
    in the requirements for the save command in various places.
    When the user clicks the save button, the forms onSubmit callback
    will be triggered.

    Usage:

    <EditFormActionButtons
        disabled={someDisabledRule}
        {...{editableThingsListViewModel, thing}}
    />
*/
const EditFormActionButtons = ({ thing, disableDelete, disableSave, editableThingsListViewModel }) => {
    const { formState } = useFormContext();
    const { isSubmitting, isValid } = formState;

    function destroy(e) {
        e.preventDefault();
        if (window.confirm('Are you sure you want to delete?')) {
            thing.destroy().then(() => {
                editableThingsListViewModel.onDestroyed(thing.id);
            });
        }
    }

    return (
        <div className="form-group row form-actions no-border">
            <button
                className="btn flat blue pull-left"
                type="submit"
                name="save"
                disabled={!isValid || isSubmitting || disableSave}
            >
                Save
            </button>

            <button
                className="btn flat red pull-right"
                name="destroy"
                type="button"
                onClick={destroy}
                disabled={!thing.id || isSubmitting || disableDelete}
            >
                Delete
            </button>

            <div className="clearfix" />
        </div>
    );
};

export default EditFormActionButtons;
